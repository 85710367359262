const errorRoutes = [
	{
		name: 'error.403',
		path: 'error403',
		component: () => import('@/views/pages/error/Error403.vue'),
	},
	{
		name: 'error.404',
		path: 'error404',
		component: () => import('@/views/pages/error/Error404.vue'),
	}
];

export default errorRoutes;