import "vuetify/styles";
import { createVuetify } from "vuetify";
import { VTreeview } from "vuetify/labs/components";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const stargres = {
	dark: false,
	colors: {
		background: "#FFFFFF",
		surface: "#FFFFFF",
		transparent: "#FFFFFF",

		black: "#000000",
		white: "#FFFFFF",
		colorGrey900: "#303030",
		colorGrey800: "#585959",
		colorGrey400: "#dbdbdb",

		primary: "#171717",
		secondary: "#E9E6E4",
		tertiary: "#FFFFFF",

		error: "#F65F21",
		info: "#4d63e8",
		success: "#18AE08",
		warning: "#F57F17",

		progressColor: "#585959",
		progressBgColor: "#585959",
	},
	variables: {
		// "input-control-height": "46px",
		"input-padding-top": "12px",
		"field-padding-bottom": "8px !important",
	},
};

const vuetify = createVuetify({
	components: {
		...components,
		VTreeview,
	},
	directives,
	theme: {
		defaultTheme: "stargres",
		themes: {
			stargres: stargres,
		},
	},
});

export default vuetify;
