import Echo from "laravel-echo";
import Pusher from "pusher-js";
import env from "../env";

window.Pusher = Pusher;
window.Echo = new Echo({
	broadcaster: "pusher",
	key: env.PUSHER_APP_KEY,
	cluster: "eu",
	forceTLS: true,
});
