import { PermissionEnums } from "@/enums/PermissionEnums";
const sharedRoutes = [
	{
		name: "dashboard",
		path: "dashboard",
		component: () => import("@/views/pages/dashboard/Dashboard.vue"),
		children: [
			{
				name: "dashboard.notifications.preview",
				path: "notifications-preview/:id",
				component: () => import("@/views/Blank.vue"),
				meta: {
					aside: () => import("@/views/pages/notifications/NotificationsPreview.vue"),
				},
			},
		],
	},
	{
		name: "profile",
		path: "profile",
		component: () => import("@/views/pages/profile/Profile.vue"),
	},
	{
		name: "files.index",
		path: "files",
		component: () => import("@/views/Blank.vue"),
		meta: {
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "files.list.dynamicFolders",
		},
		children: [
			{
				name: "files.list",
				path: "list",
				component: () => import("@/views/pages/files/list/FilesListing.vue"),
				meta: {
					permissions: PermissionEnums.FILES_LIST,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "files.list.dynamicFolders",
				},
				children: [
					{
						name: "files.list.dynamicFolders",
						path: ":path(.*)*",
						component: () => import("@/views/Blank.vue"),
					},
					{
						name: "files.bulk.share",
						path: "bulk/share/:ids",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/bulk/FilesBulkShare.vue"),
						},
					},
					{
						name: "files.file.add",
						path: "file/add/:parentId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/file/FilesFileAdd.vue"),
						},
					},
					{
						name: "files.file.edit",
						path: "file/edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/file/FilesFileEdit.vue"),
						},
					},
					{
						name: "files.file.share",
						path: "file/share/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/file/FilesFileShare.vue"),
						},
					},
					{
						name: "files.file.details",
						path: "file/details/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/file/FilesFileDetails.vue"),
						},
					},
					{
						name: "files.move.index",
						path: "folder/move",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/move/FilesMove.vue"),
						},
					},
					{
						name: "files.folder.add",
						path: "folder/add/:parentId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/folder/FilesFolderAdd.vue"),
						},
					},
					{
						name: "files.folder.edit",
						path: "folder/edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/folder/FilesFolderEdit.vue"),
						},
					},
					{
						name: "files.folder.share",
						path: "folder/share/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/folder/FilesFolderShare.vue"),
						},
					},
					{
						name: "files.folder.details",
						path: "folder/details/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/files/folder/FilesFolderDetails.vue"),
						},
					},
				],
			},
		],
	},
	{
		name: "productsCards.index",
		path: "products-cards",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: PermissionEnums.PRODUCTS_LIST,
		},
		redirect: {
			name: "productsCards.list",
		},
		children: [
			{
				name: "productsCards.list",
				path: "list",
				component: () => import("@/views/pages/products/cards/list/ProductsCardsListing.vue"),
				meta: {
					permissions: PermissionEnums.PRODUCTS_LIST,
					breadcrumbs: {
						hidden: true,
					},
				},
				children: [
					{
						name: "productsCards.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							permissions: PermissionEnums.PRODUCTS_UPDATE,
							aside: () => import("@/views/pages/products/cards/ProductsCardsEdit.vue"),
						},
					},
				],
			},
			{
				name: "productsCards.history.index",
				path: "history",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.PRODUCTS_HISTORY_LIST,
				},
				redirect: {
					name: "productsCards.history.list",
				},
				children: [
					{
						name: "productsCards.history.list",
						path: "list/:productId?",
						component: () => import("@/views/pages/products/cards/history/list/ProductsCardsHistoryListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "productsCards.history.preview",
								path: "preview/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									permissions: PermissionEnums.PRODUCTS_UPDATE,
									aside: () => import("@/views/pages/products/cards/history/ProductsCardsHistoryPreview.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "productsCards.archives.index",
				path: "archives",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "productsCards.archives.list",
				},
				children: [
					{
						name: "productsCards.archives.list",
						path: "list",
						component: () => import("@/views/pages/products/cards/archives/list/ProductsCardsArchivesListing.vue"),
						meta: {
							permissions: PermissionEnums.PRODUCTS_ARCHIVED_LIST,
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "productsCards.archives.preview",
								path: "preview/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/products/cards/archives/ProductsCardsArchivesPreview.vue"),
								},
							},
						],
					},
				],
			},
		],
	},
	{
		name: "collectionsCards.index",
		path: "collections-cards",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: PermissionEnums.COLLECTION_CARD_LIST,
		},
		redirect: {
			name: "collectionsCards.list",
		},
		children: [
			{
				name: "collectionsCards.list",
				path: "list",
				component: () => import("@/views/pages/collections/cards/list/CollectionsCardsListing.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
				children: [
					{
						name: "collectionsCards.edit",
						path: "edit/:id",
						component: () => import("@/views/Blank.vue"),
						meta: {
							permissions: PermissionEnums.COLLECTION_CARD_MANAGE,
							aside: () => import("@/views/pages/collections/cards/CollectionsCardsEdit.vue"),
						},
					},
				],
			},
			{
				name: "collectionsCards.archives.index",
				path: "archives",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "collectionsCards.archives.list",
				},
				children: [
					{
						name: "collectionsCards.archives.list",
						path: "list",
						component: () => import("@/views/pages/collections/cards/archives/list/CollectionsCardsArchivesListing.vue"),
						meta: {
							permissions: PermissionEnums.COLLECTION_CARD_ARCHIVED_LIST,
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "collectionsCards.archives.preview",
								path: "preview/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/collections/cards/archives/CollectionsCardsArchivesPreview.vue"),
								},
							},
						],
					},
				],
			},
		],
	},
	{
		name: "attributes.index",
		path: "attributes",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "attributes.brands.index",
		},
		meta: {
			permissions: PermissionEnums.PRODUCTS_ATTRIBUTES_MANAGE,
		},
		children: [
			{
				name: "attributes.brands.index",
				path: "brands",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.brands.list",
				},
				children: [
					{
						name: "attributes.brands.list",
						path: "list",
						component: () => import("@/views/pages/attributes/brands/AttributesBrandsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.brands.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/brands/AttributesBrandsAdd.vue"),
								},
							},
							{
								name: "attributes.brands.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/brands/AttributesBrandsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.formats.index",
				path: "formats",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.formats.list",
				},
				children: [
					{
						name: "attributes.formats.list",
						path: "list",
						component: () => import("@/views/pages/attributes/formats/AttributesFormatsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.formats.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/formats/AttributesFormatsAdd.vue"),
								},
							},
							{
								name: "attributes.formats.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/formats/AttributesFormatsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.destinies.index",
				path: "destinies",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.destinies.list",
				},
				children: [
					{
						name: "attributes.destinies.list",
						path: "list",
						component: () => import("@/views/pages/attributes/destinies/AttributesDestiniesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.destinies.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/destinies/AttributesDestiniesAdd.vue"),
								},
							},
							{
								name: "attributes.destinies.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/destinies/AttributesDestiniesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.collections.index",
				path: "collections",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.collections.list",
				},
				children: [
					{
						name: "attributes.collections.list",
						path: "list",
						component: () => import("@/views/pages/attributes/collections/AttributesCollectionsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.collections.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/collections/AttributesCollectionsAdd.vue"),
								},
							},
							{
								name: "attributes.collections.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/collections/AttributesCollectionsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.types.index",
				path: "types",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.types.list",
				},
				children: [
					{
						name: "attributes.types.list",
						path: "list",
						component: () => import("@/views/pages/attributes/types/AttributesTypesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.types.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/types/AttributesTypesAdd.vue"),
								},
							},
							{
								name: "attributes.types.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/types/AttributesTypesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.surfaces.index",
				path: "surfaces",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.surfaces.list",
				},
				children: [
					{
						name: "attributes.surfaces.list",
						path: "list",
						component: () => import("@/views/pages/attributes/surfaces/AttributesSurfacesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.surfaces.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/surfaces/AttributesSurfacesAdd.vue"),
								},
							},
							{
								name: "attributes.surfaces.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/surfaces/AttributesSurfacesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.peis.index",
				path: "peis",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.peis.list",
				},
				children: [
					{
						name: "attributes.peis.list",
						path: "list",
						component: () => import("@/views/pages/attributes/peis/AttributesPeisListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.peis.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/peis/AttributesPeisAdd.vue"),
								},
							},
							{
								name: "attributes.peis.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/peis/AttributesPeisEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.effectsAdditional.index",
				path: "effects-additional",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.effectsAdditional.list",
				},
				children: [
					{
						name: "attributes.effectsAdditional.list",
						path: "list",
						component: () => import("@/views/pages/attributes/effects_additional/AttributesEffectsAdditionalListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.effectsAdditional.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/effects_additional/AttributesEffectsAdditionalAdd.vue"),
								},
							},
							{
								name: "attributes.effectsAdditional.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/effects_additional/AttributesEffectsAdditionalEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.dyis.index",
				path: "dyis",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.dyis.list",
				},
				children: [
					{
						name: "attributes.dyis.list",
						path: "list",
						component: () => import("@/views/pages/attributes/dyis/AttributesDyisListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.dyis.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/dyis/AttributesDyisAdd.vue"),
								},
							},
							{
								name: "attributes.dyis.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/dyis/AttributesDyisEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.pallets.index",
				path: "pallets",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.pallets.list",
				},
				children: [
					{
						name: "attributes.pallets.list",
						path: "list",
						component: () => import("@/views/pages/attributes/pallets/AttributesPalletsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.pallets.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/pallets/AttributesPalletsAdd.vue"),
								},
							},
							{
								name: "attributes.pallets.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/pallets/AttributesPalletsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.tonalities.index",
				path: "tonalities",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.tonalities.list",
				},
				children: [
					{
						name: "attributes.tonalities.list",
						path: "list",
						component: () => import("@/views/pages/attributes/tonalities/AttributesTonalitiesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.tonalities.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/tonalities/AttributesTonalitiesAdd.vue"),
								},
							},
							{
								name: "attributes.tonalities.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/tonalities/AttributesTonalitiesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.stamps.index",
				path: "stamps",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.stamps.list",
				},
				children: [
					{
						name: "attributes.stamps.list",
						path: "list",
						component: () => import("@/views/pages/attributes/stamps/AttributesStampsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.stamps.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/stamps/AttributesStampsAdd.vue"),
								},
							},
							{
								name: "attributes.stamps.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/stamps/AttributesStampsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.rectifieds.index",
				path: "rectifieds",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.rectifieds.list",
				},
				children: [
					{
						name: "attributes.rectifieds.list",
						path: "list",
						component: () => import("@/views/pages/attributes/rectifieds/AttributesRectifiedsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.rectifieds.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/rectifieds/AttributesRectifiedsAdd.vue"),
								},
							},
							{
								name: "attributes.rectifieds.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/rectifieds/AttributesRectifiedsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.frostResistances.index",
				path: "frost-resistances",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.frostResistances.list",
				},
				children: [
					{
						name: "attributes.frostResistances.list",
						path: "list",
						component: () => import("@/views/pages/attributes/frost_resistances/AttributesFrostResistancesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.frostResistances.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/frost_resistances/AttributesFrostResistancesAdd.vue"),
								},
							},
							{
								name: "attributes.frostResistances.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/frost_resistances/AttributesFrostResistancesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.measureUnits.index",
				path: "measure-units",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.measureUnits.list",
				},
				children: [
					{
						name: "attributes.measureUnits.list",
						path: "list",
						component: () => import("@/views/pages/attributes/measure_units/AttributesMeasureUnitsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.measureUnits.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/measure_units/AttributesMeasureUnitsAdd.vue"),
								},
							},
							{
								name: "attributes.measureUnits.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/measure_units/AttributesMeasureUnitsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.inspirations.index",
				path: "inspirations",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.inspirations.list",
				},
				children: [
					{
						name: "attributes.inspirations.list",
						path: "list",
						component: () => import("@/views/pages/attributes/inspirations/AttributesInspirationsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.inspirations.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/inspirations/AttributesInspirationsAdd.vue"),
								},
							},
							{
								name: "attributes.inspirations.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/inspirations/AttributesInspirationsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.nonslip.index",
				path: "nonslip",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.nonslip.list",
				},
				children: [
					{
						name: "attributes.nonslip.list",
						path: "list",
						component: () => import("@/views/pages/attributes/nonslip/AttributesNonslipListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.nonslip.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/nonslip/AttributesNonslipAdd.vue"),
								},
							},
							{
								name: "attributes.nonslip.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/nonslip/AttributesNonslipEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.bareFeet.index",
				path: "bare-feet",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.bareFeet.list",
				},
				children: [
					{
						name: "attributes.bareFeet.list",
						path: "list",
						component: () => import("@/views/pages/attributes/bare_feet/AttributesBareFeetListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.bareFeet.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/bare_feet/AttributesBareFeetAdd.vue"),
								},
							},
							{
								name: "attributes.bareFeet.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/bare_feet/AttributesBareFeetEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.offersPoland.index",
				path: "offers-poland",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.offersPoland.list",
				},
				children: [
					{
						name: "attributes.offersPoland.list",
						path: "list",
						component: () => import("@/views/pages/attributes/offers_poland/AttributesOffersPolandListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.offersPoland.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/offers_poland/AttributesOffersPolandAdd.vue"),
								},
							},
							{
								name: "attributes.offersPoland.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/offers_poland/AttributesOffersPolandEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.offersExport.index",
				path: "offers-export",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.offersExport.list",
				},
				children: [
					{
						name: "attributes.offersExport.list",
						path: "list",
						component: () => import("@/views/pages/attributes/offers_export/AttributesOffersExportListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.offersExport.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/offers_export/AttributesOffersExportAdd.vue"),
								},
							},
							{
								name: "attributes.offersExport.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/offers_export/AttributesOffersExportEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.shapedSurfaces.index",
				path: "shaped-surfaces",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.shapedSurfaces.list",
				},
				children: [
					{
						name: "attributes.shapedSurfaces.list",
						path: "list",
						component: () => import("@/views/pages/attributes/shaped_surfaces/AttributesShapedSurfacesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.shapedSurfaces.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/shaped_surfaces/AttributesShapedSurfacesAdd.vue"),
								},
							},
							{
								name: "attributes.shapedSurfaces.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/shaped_surfaces/AttributesShapedSurfacesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.colors.index",
				path: "colors",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.colors.list",
				},
				children: [
					{
						name: "attributes.colors.list",
						path: "list",
						component: () => import("@/views/pages/attributes/colors/AttributesColorsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.colors.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/colors/AttributesColorsAdd.vue"),
								},
							},
							{
								name: "attributes.colors.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/colors/AttributesColorsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.technologies.index",
				path: "technologies",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.technologies.list",
				},
				children: [
					{
						name: "attributes.technologies.list",
						path: "list",
						component: () => import("@/views/pages/attributes/technologies/AttributesTechnologiesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.technologies.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/technologies/AttributesTechnologiesAdd.vue"),
								},
							},
							{
								name: "attributes.technologies.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/technologies/AttributesTechnologiesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.coloringMass.index",
				path: "coloring-mass",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.coloringMass.list",
				},
				children: [
					{
						name: "attributes.coloringMass.list",
						path: "list",
						component: () => import("@/views/pages/attributes/coloring_masses/AttributesColoringMassesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.coloringMass.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/coloring_masses/AttributesColoringMassesAdd.vue"),
								},
							},
							{
								name: "attributes.coloringMass.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/coloring_masses/AttributesColoringMassesEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "attributes.stainingGlazes.index",
				path: "staining-glaze",
				component: () => import("@/views/Blank.vue"),
				redirect: {
					name: "attributes.stainingGlazes.list",
				},
				children: [
					{
						name: "attributes.stainingGlazes.list",
						path: "list",
						component: () => import("@/views/pages/attributes/staining_glazes/AttributesStainingGlazesListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "attributes.stainingGlazes.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/staining_glazes/AttributesStainingGlazesAdd.vue"),
								},
							},
							{
								name: "attributes.stainingGlazes.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/attributes/staining_glazes/AttributesStainingGlazesEdit.vue"),
								},
							},
						],
					},
				],
			},
		],
	},
	{
		name: "users",
		path: "users",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "users.index",
		},
		meta: {
			permissions: PermissionEnums.USERS_MANAGE,
		},
		children: [
			{
				name: "users.index",
				path: "",
				component: () => import("@/views/pages/users/UsersIndex.vue"),
			},
			{
				name: "users.administrators.index",
				path: "administrators",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_ADMINS,
				},
				redirect: {
					name: "users.administrators.list",
				},
				children: [
					{
						name: "users.administrators.list",
						path: "list",
						component: () => import("@/views/pages/users/administrators/UsersAdministratorsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.administrators.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/administrators/UsersAdministratorsAdd.vue"),
								},
							},
							{
								name: "users.administrators.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/administrators/UsersAdministratorsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.directors.index",
				path: "directors",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_DIRECTORS,
				},
				redirect: {
					name: "users.directors.list",
				},
				children: [
					{
						name: "users.directors.list",
						path: "list",
						component: () => import("@/views/pages/users/directors/UsersDirectorsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.directors.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/directors/UsersDirectorsAdd.vue"),
								},
							},
							{
								name: "users.directors.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/directors/UsersDirectorsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.marketers.index",
				path: "marketers",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_MARKETERS,
				},
				redirect: {
					name: "users.marketers.list",
				},
				children: [
					{
						name: "users.marketers.list",
						path: "list",
						component: () => import("@/views/pages/users/marketers/UsersMarketersListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.marketers.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/marketers/UsersMarketersAdd.vue"),
								},
							},
							{
								name: "users.marketers.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/marketers/UsersMarketersEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.sellers.index",
				path: "sellers",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_SELLERS,
				},
				redirect: {
					name: "users.sellers.list",
				},
				children: [
					{
						name: "users.sellers.list",
						path: "list",
						component: () => import("@/views/pages/users/sellers/UsersSellersListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.sellers.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/sellers/UsersSellersAdd.vue"),
								},
							},
							{
								name: "users.sellers.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/sellers/UsersSellersEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.implementers.index",
				path: "implementers",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_IMPLEMENTERS,
				},
				redirect: {
					name: "users.implementers.list",
				},
				children: [
					{
						name: "users.implementers.list",
						path: "list",
						component: () => import("@/views/pages/users/implementers/UsersImplementersListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.implementers.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/implementers/UsersImplementersAdd.vue"),
								},
							},
							{
								name: "users.implementers.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/implementers/UsersImplementersEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.controllersQuality.index",
				path: "controllers-quality",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_QUALITY_CONTROLLERS,
				},
				redirect: {
					name: "users.controllersQuality.list",
				},
				children: [
					{
						name: "users.controllersQuality.list",
						path: "list",
						component: () => import("@/views/pages/users/controllers_quality/UsersControllersQualityListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.controllersQuality.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/controllers_quality/UsersControllersQualityAdd.vue"),
								},
							},
							{
								name: "users.controllersQuality.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/controllers_quality/UsersControllersQualityEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.productions.index",
				path: "productions",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_PRODUCTIONS,
				},
				redirect: {
					name: "users.productions.list",
				},
				children: [
					{
						name: "users.productions.list",
						path: "list",
						component: () => import("@/views/pages/users/productions/UsersProductionsListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.productions.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/productions/UsersProductionsAdd.vue"),
								},
							},
							{
								name: "users.productions.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/productions/UsersProductionsEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.productionPlanning.index",
				path: "production-planning",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_PRODUCTION_PLANNING,
				},
				redirect: {
					name: "users.productionPlanning.list",
				},
				children: [
					{
						name: "users.productionPlanning.list",
						path: "list",
						component: () => import("@/views/pages/users/production_planning/UsersProductionPlanningListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.productionPlanning.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/production_planning/UsersProductionPlanningAdd.vue"),
								},
							},
							{
								name: "users.productionPlanning.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/production_planning/UsersProductionPlanningEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "users.public.index",
				path: "public",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_USERS,
				},
				redirect: {
					name: "users.public.list",
				},
				children: [
					{
						name: "users.public.list",
						path: "list",
						component: () => import("@/views/pages/users/public/UsersPublicListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [
							{
								name: "users.public.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/public/UsersPublicAdd.vue"),
								},
							},
							{
								name: "users.public.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/public/UsersPublicEdit.vue"),
								},
							},
						],
					},
					{
						name: "users.public.groups.index",
						path: "groups",
						component: () => import("@/views/Blank.vue"),
						redirect: {
							name: "users.public.groups.list",
						},
						children: [
							{
								name: "users.public.groups.list",
								path: "list",
								component: () => import("@/views/pages/users/public/groups/list/UsersPublicGroupsListing.vue"),
								meta: {
									breadcrumbs: {
										hidden: true,
									},
								},
								children: [
									{
										name: "users.public.groups.add",
										path: "add",
										component: () => import("@/views/Blank.vue"),
										meta: {
											aside: () => import("@/views/pages/users/public/groups/UsersPublicGroupsAdd.vue"),
										},
									},
									{
										name: "users.public.groups.edit",
										path: "edit/:id",
										component: () => import("@/views/Blank.vue"),
										meta: {
											aside: () => import("@/views/pages/users/public/groups/UsersPublicGroupsEdit.vue"),
										},
									},
								],
							},
						],
					},
				],
			},
			{
				name: "users.loginHistory.index",
				path: "login-history",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnums.USERS_MANAGE_ADMINS,
				},
				redirect: {
					name: "users.loginHistory.list",
				},
				children: [
					{
						name: "users.loginHistory.list",
						path: "list",
						component: () => import("@/views/pages/users/login_history/list/UsersLoginHistoryListing.vue"),
						meta: {
							breadcrumbs: {
								hidden: true,
							},
						},
					},
				],
			},
		],
	},
	{
		name: "notifications.index",
		path: "notifications",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "notifications.list",
		},
		children: [
			{
				name: "notifications.list",
				path: "list",
				component: () => import("@/views/pages/notifications/list/NotificationsListing.vue"),
				children: [
					{
						name: "notifications.preview",
						path: "preview/:id",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/notifications/NotificationsPreview.vue"),
						},
					},
				],
			},
		],
	},
	{
		name: "settings.index",
		path: "settings",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnums.SETTINGS_MANAGE],
		},
		redirect: {
			name: "settings.mail",
		},
		children: [
			{
				name: "settings.mail",
				path: "mail",
				component: () => import("@/views/pages/settings/SettingsMail.vue"),
			},
		],
	},
];
export default sharedRoutes;
