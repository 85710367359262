export const routes = {
	error403: "error.403",
	error404: "error.404",
	localeChoose: "localeChoose",

	home: "dashboard",

	dashboard: "dashboard",

	profile: "profile",

	files: {
		list: "files.list",
		dynamicFolders: "files.list.dynamicFolders",
		move: "files.move.index",
	},

	login: "auth.login",
	logout: "auth.logout",
	passwordForgot: "auth.password.forgot",
	passwordReset: "auth.password.reset",

	notifications: "notifications.index",
};
