import { createRouter, createWebHistory } from "vue-router";
import MiddlewareController from "@/middlewares/MiddlewareController.js";
import LocaleService from "@/services/LocaleService";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { useAsideStore } from "@/stores/useAsideStore";
import { setLocale } from "yup";
import i18n from "@/i18n";
import routes from "./general";
import messages from "@/lang";

const router = createRouter({
	history: createWebHistory(),
	routes: routes,
});

router.beforeEach((to, from, next) => {
	useLoadingStore().routeStart(to.name);

	const middlewareNext = MiddlewareController({
		to,
		from,
		next,
	});
	if (!!middlewareNext) {
		return middlewareNext();
	}

	return next();
});

router.afterEach((to, from) => {
	LocaleService.saveLocale(to.params.locale);
	i18n.global.locale.value = LocaleService.getLocale();

	if (messages[LocaleService.getLocale()]["formValidate"]["yup"]) {
		setLocale(messages[LocaleService.getLocale()]["formValidate"]["yup"]);
	}

	useLoadingStore().routeStop(to.name);

	useAsideStore().setParent(from);
});

export default router;
