export const RoleEnums = {
	ADMIN: 1,
	DIRECTOR: 2,
	MARKETER: 3,
	SELLER: 4,
	IMPLEMENTER: 5,
	USER: 6,
	QUALITY_CONTROL: 7,
	PRODUCTION: 8,
	PRODUCTION_PLANNING: 9,

	1: "admin",
	2: "director",
	3: "marketer",
	4: "seller",
	5: "implementer",
	6: "user",
	7: "quality_control",
	8: "production",
	9: "production_planning",
};
