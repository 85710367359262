import axios from "axios";
import i18n from "@/i18n";
import { useLoadingStore } from "@/stores/useLoadingStore";
import { useAlertsStore } from "@/stores/useAlertsStore";
import { useAuthStore } from "@/stores/useAuthStore";

export const repositoryClientModule = axios;

export const repositoryClient = repositoryClientModule.create({
	withCredentials: true,
	withXSRFToken: true,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
	},
});

repositoryClient.interceptors.request.use(
	(config) => {
		useLoadingStore().requestStart(config.url);

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

repositoryClient.interceptors.response.use(
	(response) => {
		useLoadingStore().requestStop(response.config.url);

		return response;
	},
	(error) => {
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}

		useLoadingStore().requestStop(error.config.url);

		if (typeof error.response === "undefined") {
			return Promise.reject(error);
		}

		const alertsStore = useAlertsStore();
		if (400 === error.response.status) {
			alertsStore.addError(i18n.global.t("request.error.400"));
		}

		if (401 === error.response.status) {
			useAuthStore().clearUser();
		}

		if (403 === error.response.status) {
			alertsStore.addError(i18n.global.t("request.error.403"));
		}

		if (503 === error.response.status) {
			alertsStore.addError(i18n.global.t("request.error.503"));

			setTimeout(function () {
				window.location.reload();
			}, 3000);
		}

		if (500 === error.response.status) {
			alertsStore.addError(i18n.global.t("request.error.500"));
		}

		return Promise.reject(error);
	}
);

export default { repositoryClientModule, repositoryClient };
