<template>
	<div id="bottom-sheet-wrapper" />
	<router-view />
</template>

<script>
export default {
	name: "App",
	setup() {},
};
</script>

<style scoped></style>
