import { useLocaleStore } from "@/stores/useLocaleStore";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";
import LocaleService from "@/services/LocaleService";
export const LocaleSelectedMiddleware = ({ to, next }) => {
	const localeStore = useLocaleStore();

	if (!localeStore.getIsLocaleSelected && to.name !== RoutesService.routes.localeChoose) {
		localeStore.setTo(to);
		return RouterService.push({ name: RoutesService.routes.localeChoose });
	}

	if (localeStore.getIsLocaleSelected && to.name !== RoutesService.routes.localeChoose) {
		const localeSelected = localeStore.getLocaleSelected;
		if (!LocaleService.exists(localeSelected)) {
			localeStore.setLocaleSelected(null);
			return RouterService.push({ name: RoutesService.routes.localeChoose });
		}

		const thisLocale = to.params.locale;
		if (thisLocale !== localeSelected) {
			to.params.locale = localeSelected;
			return RouterService.push({
				...to,
				params: {
					locale: localeSelected,
				},
			});
		}
	}

	return next();
};

export default LocaleSelectedMiddleware;
