import { defineStore } from "pinia";
import IDService from "@/services/IDService";
import AlertsService from "@/services/AlertsService";

export const useAlertsStore = defineStore("alerts", {
	state: () => ({
		alerts: [],
	}),

	getters: {
		getAlerts: (state) => {
			return state.alerts;
		},
	},

	actions: {
		add(alert) {
			this.pushAlert(alert);
		},
		addInfo(alert) {
			this.pushAlert(alert, AlertsService.types.info);
		},
		addOk(alert) {
			this.addSuccess(alert);
		},
		addSuccess(alert) {
			this.pushAlert(alert, AlertsService.types.success);
		},
		addWarning(alert) {
			this.pushAlert(alert, AlertsService.types.warning);
		},
		addError(alert) {
			this.pushAlert(alert, AlertsService.types.error);
		},

		remove(alert) {
			this.alerts = this.alerts.filter((oldAlert) => {
				return oldAlert.id !== alert.id;
			});
		},

		pushAlert(alert = null, type = null) {
			let note = {
				id: `alert-id--${IDService.s4()}`,
				title: "",
				content: "",
				type: type ? type : AlertsService.types.info,
				timeout: AlertsService.timeout,
				props: {},
			};

			if (typeof alert == "string") {
				alert = {
					content: alert,
				};
			}

			this.alerts.push({
				...note,
				...alert,
			});
		},
	},
});
