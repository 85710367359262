import env from "@/env";
import LocaleService from "@/services/LocaleService";
import RouterService from "@/services/RouterService";
import { repositoryClient, repositoryClientModule } from "@/repositories/clients/ClientAxios";
import { useLoadingStore } from "@/stores/useLoadingStore";

export const baseURL = `${env.API_URL}/${LocaleService.getLocale()}`;

const prepareUrl = (url) => {
	if (!url) {
		return;
	}

	if (url.indexOf("http://") === -1 && url.indexOf("https://") === -1) {
		return `${baseURL}/${url}`;
	}

	return url;
};

const abortControllers = {};

const getAbortController = (url) => {
	if (!url) {
		return null;
	}

	if (abortControllers && abortControllers[url]) {
		abortControllers[url].abort(url);
		useLoadingStore().requestStop(url);
		delete abortControllers[url];
	}
	abortControllers[url] = new AbortController();

	return abortControllers[url];
};

export const module = repositoryClientModule;

export const get = (url, params = null, abort = true, loadingSilent = false) => {
	url = prepareUrl(url);

	let queryUrl = RouterService.getQuery();
	if (url.indexOf("?") != -1) {
		queryUrl = "&" + queryUrl.substr(1);
	}

	let data = {};
	if (null !== params) {
		data.params = params;
	}
	if (true === abort) {
		const abortController = getAbortController(url);
		data.signal = abortController.signal;
	}

	const theUrl = `${url}${queryUrl}`;

	if (loadingSilent) useLoadingStore().setRequestSilent(theUrl);

	return new Promise((resolve, reject) => {
		repositoryClient
			.get(theUrl, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getRaw = (url, params = null, abort = true, loadingSilent = false) => {
	url = prepareUrl(url);

	let data = {};
	if (null !== params) {
		data.params = params;
	}
	if (true === abort) {
		const abortController = getAbortController(url);
		data.signal = abortController.signal;
	}

	if (loadingSilent) useLoadingStore().setRequestSilent(url);

	return new Promise((resolve, reject) => {
		repositoryClient
			.get(`${url}`, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const post = (url, data = {}, abort = false, postConfig = {}, loadingSilent = false) => {
	url = prepareUrl(url);

	if (true === abort) {
		const abortController = getAbortController(url);
		data.signal = abortController.signal;
	}

	if (loadingSilent) useLoadingStore().setRequestSilent(url);

	return new Promise((resolve, reject) => {
		repositoryClient
			.post(`${url}`, data, postConfig)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const put = (url, data = {}, loadingSilent = false) => {
	url = prepareUrl(url);

	if (loadingSilent) useLoadingStore().setRequestSilent(url);

	return new Promise((resolve, reject) => {
		repositoryClient
			.put(`${url}`, data)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const del = (url, data = {}, loadingSilent = false) => {
	url = prepareUrl(url);

	if (loadingSilent) useLoadingStore().setRequestSilent(url);

	return new Promise((resolve, reject) => {
		repositoryClient
			.delete(`${url}`, {
				data: data,
			})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getOne = (url, id) => {
	url += id ? "/" + id : "";
	return getRaw(url);
};

export const create = (url, data) => {
	return post(`${url}`, data);
};

export const update = (url, id, data) => {
	url += id ? "/" + id : "";
	return put(`${url}`, data);
};

export const deleteOne = (url, id) => {
	url += id ? "/" + id : "";
	return del(`${url}`);
};

export default { module, baseURL, get, getRaw, post, put, del, getOne, create, update, deleteOne };
