import { computed } from "vue";
import router from "@/routes";
import LocaleService from "@/services/LocaleService";

export const currentRoute = computed(() => {
	return router.currentRoute.value;
});

export const getRouter = () => {
	return router;
};

export const getLayout = () => {
	const route = currentRoute.value;
	let layout = "default-layout";
	if (route.meta && route.meta.layout) {
		layout = `${route.meta.layout}-layout`;
	}
	return _.kebabCase(layout);
};

export const push = (to) => {
	if (typeof to == "object") {
		const toDefault = {
			params: {
				locale: LocaleService.getLocale(),
			},
		};
		to = { ...toDefault, ...to };
	}
	return router.push(to);
};

export const replace = (to) => {
	if (typeof to == "object") {
		const toDefault = {
			params: {
				locale: LocaleService.getLocale(),
			},
		};
		to = { ...toDefault, ...to };
	}
	return router.replace(to);
};

export const go = (delta) => {
	router.go(delta);
};

export const getQuery = () => {
	return window.location.search;
};

export const getByKey = (key, defaultValue = null) => {
	const route = currentRoute.value;

	if (undefined === route.query[key]) {
		return defaultValue;
	}

	return route.query[key];
};

export const getParam = (key = null, defaultValue = null) => {
	const route = currentRoute.value;
	if (!key) {
		return route.params;
	}

	if (undefined === route.params[key]) {
		return defaultValue;
	}

	return route.params[key];
};

export const changeQuery = (query = {}, exclude = []) => {
	const route = router.currentRoute;
	let newQuery = { ...route.value.query, ...query };

	exclude.forEach((toExclude) => {
		delete newQuery[toExclude];
	});

	const to = { ...route.value, ...{ query: newQuery } };

	return push(to)
		.then(() => {})
		.catch(() => {});
};

export const objectToQueryString = (query = {}) => {
	return (
		"?" +
		Object.keys(query)
			.map((key) => {
				if ("object" === typeof query[key]) {
					return query[key].map((param) => `${key}[]=${param}`).join("&");
				}

				return `${key}=${query[key]}`;
			})
			.join("&")
	);
};

export const getRouteByName = (name) => {
	let route = null;
	const routes = router.getRoutes();
	Object.keys(routes).some((path) => {
		if (routes[path].name === name) {
			route = routes[path];
			return true;
		}
	});
	return route;
};

export const hasParent = (name) => {
	if (!name) return true;

	if (!("matched" in currentRoute.value)) return false;

	if (!currentRoute.value.matched.length) return false;

	return currentRoute.value.matched.reduce((result, element) => {
		if (element.name === name) result = true;

		return result;
	}, false);
};

export default {
	getRouter,
	currentRoute,
	getLayout,
	push,
	replace,
	go,
	getQuery,
	getByKey,
	getParam,
	changeQuery,
	objectToQueryString,
	getRouteByName,
	hasParent,
};
