import { computed, defineAsyncComponent } from "vue";
import { defineStore } from "pinia";
import RouterService from "@/services/RouterService";
export const useAsideStore = defineStore("aside", {
	state: () => ({
		isOpen: false,
		parent: null,
		kind: {},
		vBindNavDrawer: {},
		confirmConfig: null,
	}),

	getters: {
		getRouteAside() {
			const currentRoute = RouterService.currentRoute;

			if (!currentRoute.value.meta) return null;
			if (!("aside" in currentRoute.value.meta)) return null;

			return currentRoute.value.meta.aside;
		},
		getIsAside() {
			return !!this.getRouteAside;
		},
		getAsideComponent() {
			if (!this.getIsAside) return null;

			let component = this.getRouteAside;
			if (typeof component === "String") {
				component = () => import(/* @vite-ignore */ component);
			}

			return defineAsyncComponent(component);
		},
		getIsOpen(state) {
			return !!state.isOpen;
		},
		getParent(state) {
			const currentRoute = RouterService.currentRoute;

			const routeParent = computed(() => {
				if (!currentRoute.value.matched) return false;
				if (currentRoute.value.matched.length <= 1) return false;

				return currentRoute.value.matched[currentRoute.value.matched.length - 2];
			});

			let theParent = routeParent.value;

			if (state.parent && state.parent.name !== currentRoute.value.name) theParent = state.parent;

			if (!theParent || typeof theParent !== "object" || !("name" in theParent)) return null;

			if (!theParent) return null;

			return {
				name: theParent.name,
				params: theParent.params ? theParent.params : {},
				query: theParent.query ? theParent.query : {},
			};
		},
		getKind(state) {
			const currentRoute = RouterService.currentRoute;

			if (!(currentRoute.value.name in state.kind)) return "";

			return state.kind[currentRoute.value.name];
		},
		getVBindNavDrawer(state) {
			const currentRoute = RouterService.currentRoute;

			if (!(currentRoute.value.name in state.vBindNavDrawer)) return {};

			return state.vBindNavDrawer[currentRoute.value.name];
		},
		getConfirm(state) {
			return state.confirmConfig;
		},
	},

	actions: {
		openAside() {
			this.isOpen = true;
		},
		closeAside() {
			this.isOpen = false;
		},
		toggleAside() {
			this.isOpen = !this.isOpen;
		},
		setIsOpen(isOpen) {
			this.isOpen = isOpen;
		},
		setupOpen() {
			if (this.getIsAside) this.openAside();
			else this.closeAside();
		},
		setParent(parent) {
			this.parent = parent;
		},
		redirectToParent() {
			if (!this.getParent) return false;
			return RouterService.push(this.getParent);
		},
		redirectToParentIfShould() {
			if (!this.getIsAside) return false;
			if (this.getIsOpen) return false;

			this.redirectToParent();
		},
		setKind(kind) {
			const currentRoute = RouterService.currentRoute;
			this.kind[currentRoute.value.name] = kind;
		},
		setVBindNavDrawer(vBind) {
			const currentRoute = RouterService.currentRoute;
			this.vBindNavDrawer[currentRoute.value.name] = vBind;
		},
		setConfirm(config) {
			this.confirmConfig = config;
		},
		confirmOnConfirm() {
			if (!this.getConfirm) return false;
			if (!this.getConfirm.onConfirm) return false;

			return this.getConfirm.onConfirm();
		},
		confirmOnCancel() {
			if (!this.getConfirm) return false;
			if (!this.getConfirm.onCancel) return false;

			return this.getConfirm.onCancel();
		},
		confirmOnClose() {
			if (!this.getConfirm) return false;
			if (!this.getConfirm.onClose) return false;

			return this.getConfirm.onClose();
		},
	},
});
