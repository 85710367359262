export const PermissionEnums = {
	SETTINGS_MANAGE: "settings_manage",
	USERS_LIST: "users_list",
	USERS_MANAGE: "users_manage",

	USERS_MANAGE_ADMINS: "users_manage_admins",
	USERS_MANAGE_USERS: "users_manage_users",
	USERS_MANAGE_DIRECTORS: "users_manage_directors",
	USERS_MANAGE_MARKETERS: "users_manage_marketers",
	USERS_MANAGE_SELLERS: "users_manage_seller",
	USERS_MANAGE_IMPLEMENTERS: "users_manage_implementer",
	USERS_MANAGE_QUALITY_CONTROLLERS: "users_manage_quality_controllers",
	USERS_MANAGE_PRODUCTIONS: "users_manage_productions",
	USERS_MANAGE_PRODUCTION_PLANNING: "users_manage_production_planning",

	USERS_ASSIGN_MANAGERS: "users_assign_managers",
	USERS_ASSIGN_TWO_STEP_VERIFICATION: "users_assign_two_step_verification",

	USER_GROUPS_LIST: "user_groups_list",
	USER_GROUPS_MANAGE: "user_groups_manage",
	USER_LOGS_LIST: "user_logs_list",

	FILES_LIST: "files_list",
	FILES_MANAGE: "files_manage",
	FILES_MANAGE_ALL: "files_manage_all",
	FILES_VIEW_CREATOR: "files_view_creator",
	FILES_MANAGE_PERMISSIONS: "files_manage_permissions",
	FILES_MANAGE_WITHOUT_PERMISSIONS: "files_manage_without_permissions",
	OTHER_FILES_MANAGE: "other_files_manage",
	FILES_COPY_PUBLIC_LINK: "files_copy_public_link",

	PRODUCTS_LIST: "products_list",
	PRODUCTS_CREATE: "products_create",
	PRODUCTS_CREATE_PDF: "products_create_pdf",
	PRODUCTS_UPDATE: "products_update",
	PRODUCTS_DELETE: "products_delete",
	PRODUCTS_DELETE_EMPTY: "products_delete_empty",

	PRODUCTS_HISTORY_LIST: "products_history_list",
	PRODUCTS_ARCHIVED_LIST: "products_archived_list",
	PRODUCTS_ARCHIVED_CREATE: "products_archived_create",

	PRODUCTS_ATTRIBUTES_CREATE: "products_attributes_create",
	PRODUCTS_ATTRIBUTES_LIST: "products_attributes_list",
	PRODUCTS_ATTRIBUTES_MANAGE: "products_attributes_manage",

	PRODUCT_COMMENTS_LIST: "product_comments_list",
	PRODUCT_COMMENTS_MANAGE: "product_comments_manage",

	PRODUCTS_FIELDS_ADMIN: "products_fields_admin",
	PRODUCTS_FIELDS_DIRECTOR: "products_fields_director",
	PRODUCTS_FIELDS_MARKETER: "products_fields_marketer",
	PRODUCTS_FIELDS_SELLER: "products_fields_seller",
	PRODUCTS_FIELDS_IMPLEMENTER: "products_fields_implementer",

	COLLECTION_CARD_LIST: "collection_card_list",
	COLLECTION_CARD_MANAGE: "collection_card_manage",
	COLLECTION_CARD_DESTROY: "collection_card_destroy",
	COLLECTION_CARD_ARCHIVED_LIST: "collection_card_archived_list",
	COLLECTION_CARD_ARCHIVED_CREATE: "collection_card_archived_create",

	NOTIFICATION_WIDGET: "notification_widget",

	READ: "read",
	OWNER: "owner",
};
