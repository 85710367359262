import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
export const useLocaleStore = defineStore("locale", {
	state: () => ({
		localeSelected: useLocalStorage("pinia/locale/selected", ""),
		to: null,
	}),

	getters: {
		getLocaleSelected(state) {
			return state.localeSelected;
		},
		getIsLocaleSelected(state) {
			return !!state.localeSelected;
		},
		getTo(state) {
			return state.to;
		},
	},

	actions: {
		setLocaleSelected(locale) {
			this.localeSelected = locale;
		},
		setTo(to) {
			this.to = to;
		},
	},
});
