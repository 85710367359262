import config from "@/config";
import env from "@/env";
import LocaleService from "@/services/LocaleService";
import { useAuthStore } from "@/stores/useAuthStore";
import { RoleEnums } from "@/enums/RoleEnums";

export const routes = config.routes;

export const staticPageRoute = (slug) => {
	if (!slug) {
		return false;
	}

	return `/${LocaleService.getLocale()}/${slug}`;
};

export const routeTo = (name, params = {}, query = {}, hash = "") => {
	if (!name) return null;

	if (!params) params = {};

	return {
		name: name,
		params: {
			...{ locale: LocaleService.getLocale() },
			...params,
		},
		query: query,
		hash: hash,
	};
};

export const apiLink = (route, $query = {}) => {
	const baseURL = `${env.API_URL}/${LocaleService.getLocale()}`;

	let url = route.toString();

	if (!_.isEmpty($query)) {
		url +=
			"?" +
			Object.keys($query)
				.map((key) => {
					let keyValue = $query[key];
					if (!Array.isArray(keyValue)) keyValue = [keyValue];

					return keyValue
						.reduce((result, element) => {
							result.push(key + "=" + element);
							return result;
						}, [])
						.join("&");
				})
				.join("&");
	}

	return `${baseURL}/${url}`;
};

export const defaultRoute = () => {
	const authStore = useAuthStore();

	switch (authStore.getUserRoleId) {
		case RoleEnums.ADMIN_ROLE_ID:
			return routes.home;
			break;
		case RoleEnums.DIRECTOR_ROLE_ID:
			return routes.home;
			break;
		case RoleEnums.MARKETER_ROLE_ID:
			return routes.home;
			break;
		case RoleEnums.SELLER_ROLE_ID:
			return routes.home;
			break;
		case RoleEnums.IMPLEMENTER_ROLE_ID:
			return routes.home;
			break;
		case RoleEnums.USER_ROLE_ID:
			return routes.home;
			break;
		default:
			return routes.home;
			break;
	}

	return "";
};

export default {
	routes,
	staticPageRoute,
	routeTo,
	defaultRoute,
	apiLink,
};
