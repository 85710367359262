import RepositoryService from "@/services/RepositoryService";
const resource = "auth";

export const check = () => {
	return RepositoryService.getRaw(`${resource}/me`);
};

export const login = (credentials) => {
	return RepositoryService.post(`${resource}/login`, {
		email: credentials.email,
		password: credentials.password,
		remember: credentials.remember,
	});
};

export const logout = () => {
	return RepositoryService.post(`${resource}/logout`);
};

export const passwordForgot = (data) => {
	return RepositoryService.post(`${resource}/password-forget`, {
		email: data.email,
	});
};

export const checkPasswordReset = (token) => {
	return RepositoryService.get(`${resource}/password-reset/${token}`);
};
export const passwordReset = (data) => {
	return RepositoryService.post(`${resource}/password-reset/reset`, {
		token: data.token,
		email: data.email,
		password: data.password,
		password_confirmation: data.password_confirmation,
	});
};
export const verification = (data) => {
	return RepositoryService.post(`${resource}/verification`, {
		token: data.token,
	});
};

export const verificationTwoStep = (data) => {
	return RepositoryService.post(`${resource}/two-step-verification`, {
		token: data.token,
		code: data.code,
	});
};

export const update = (data) => {
	return RepositoryService.put(`${resource}/me`, data);
};

export default { check, login, logout, passwordForgot, checkPasswordReset, passwordReset, update, verification, verificationTwoStep };
